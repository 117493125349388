import axios from "axios";
import {
  baseUrl,
  getOptions,
  getOptionsForAction,
  postOptions,
  putOptions,
  deleteOptions,
  patchOptions, patchOptionsForAction,
} from "../config.js";
class AccountsService {
  constructor(baseUrl) {
    this.basePath = "/accounts";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  getAccounts() {
    return axios(getOptions(this.baseUrl));
  }

  getProfile() {
    return axios(getOptions(this.baseUrl, `/profile`));
  }

  checkSubscriptionPostAction() {
    return axios(getOptionsForAction(this.baseUrl, `/post-actions`));
  }

  updateAccounts() {
    return axios(putOptions(this.baseUrl));
  }

  upgradeSubscription(data) {
    return axios(putOptions(this.baseUrl, `/subscriptions/upgrade`, data));
  }

  unsubscribeSubscription(data) {
    return axios(
      deleteOptions(this.baseUrl, `/subscriptions/unsubscribe`, data)
    );
  }

  restartSubscription(data) {
    return axios(postOptions(this.baseUrl, `/subscriptions/restart`, data));
  }

  confirmPaymentProfileChange(data) {
    return axios(
      postOptions(
        this.baseUrl,
        `/subscriptions/payment-profile/change/confirm`,
        data
      )
    );
  }

  paymentProfileChange(data) {
    return axios(
      postOptions(this.baseUrl, `/subscriptions/payment-profile/change`, data)
    );
  }

  getSubscriptions() {
    return axios(getOptions(this.baseUrl, `/subscriptions`));
  }

  addSubscriptions(data) {
    return axios(postOptions(this.baseUrl, `/subscriptions`, data));
  }

  subscribe(data) {
    return axios(postOptions(this.baseUrl, `/subscribe`, data));
  }

  actions(data) {
    return axios(postOptions(this.baseUrl, `/actions`, data));
  }

  logoutAll(data) {
    return axios(postOptions(this.baseUrl, `/logout-all`, data));
  }

  addPaymentProfile(data) {
    return axios(postOptions(this.baseUrl, `/payment-profile`, data));
  }

  selectPaymentProfile(data){
    return axios(postOptions(this.baseUrl, `/payment-profile/select`, data))
  }

  deletePaymentProfile(data){
    return axios(deleteOptions(this.baseUrl, `/payment-profile/`, data))
  }

  patchUser(data) {
    return axios(patchOptions(this.baseUrl, "", data));
  }

  patchUserForAction(data) {
    return axios(patchOptionsForAction(this.baseUrl, "/post-actions", data));
  }

  addCoupon(data) {
    return axios(postOptions(this.baseUrl, "/subscriptions/coupons", data));
  }
}

export default new AccountsService(baseUrl);  //eslint-disable-line import/no-anonymous-default-export
