import axios from "axios";
import {
  baseUrl,
  postOptions,
} from "../config.js";
class DeviceLinkService {
  constructor(baseUrl) {
    this.basePath = "/device";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  generateAuthTokens() {
    return axios(postOptions(this.baseUrl));
  }

  pollUsingDeviceCode(data) {
    return axios(postOptions(this.baseUrl, "/token", data));
  }

  linkUserWithDevice(data) {
    return axios(postOptions(this.baseUrl, "/link", data));
  }
}

export default new DeviceLinkService(baseUrl);  //eslint-disable-line import/no-anonymous-default-export
