import { Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "../GlobalStyles.css";
import "./ChargifyForm.css";
import { ReactComponent as VisaLogo } from "./visa-svg.svg";
import { ReactComponent as MasterCardLogo } from "./master-card-svg.svg";
import UserService from "../../services/UserService";
import AccountsService from "../../services/AccountsService";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import PopUp from "../Utilities/PopUp";
import GenericError from "../Utilities/GenericError";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

const chargifyHost = process.env.REACT_APP_CHARGIFY_DOMAIN;
const chargifyPKey = process.env.REACT_APP_CHARGIFY_PKEY;

const ChargifyForm = ({ paymentType }) => {
  const account = useAccount();
  let navigate = useNavigate();
  const { t } = useTranslation();

  const chargifyForm = useRef();
  const chargify = useRef(new window.Chargify());
  const [error, setError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [couponFail, setCouponFail] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [goToAcoount, setGoToAccount] = useState(false);
  const [cardInUse, setCardInUse] = useState(false);
  const [tempSubscription, setTempSubscription] = useState({});
  const [upgrade, setUpgrade] = useState(false);

  const { isLoading, addLoading, removeLoading } = useLoading();

  const {loggedIn, authData, setCheckLoggedIn} = useAuth()
  useEffect(() => {
    if(!(isLoading("INIT"))){
      addLoading("INIT")
    }
    if(loggedIn){
      if(!account.chargifyAccess){
        navigate(-1);
      // }
      // else if (!UserService.isSubscriptionAction()){//todo double check this
      //   navigate("/");
      }else{
        removeLoading("INIT")
      }
    }
    // setTimeout(() => {
    //   setLoading(false);
    //   if (!(UserService.isLoggedIn() || UserService.isSubscriptionAction())){
    //     // console.log("case2")
    //     navigate("/");
    //   }else if(!account.chargifyAccess){
    //     // console.log("case3")
    //     navigate(-1);
    //   }
    // }, 1000);
  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  const onAfterSuccessfulCouponUse = () => {
    if(promoRedirectURL){
      sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
    }
    sessionStorage.setItem("coupon-success", true);
    navigate("/CongratsPage");
  };

  const [promoRedirectURL, setPromoRedirectURL] = useState()
  const [constestRedirectURL, setContestRedirectURL] = useState();
  const [constest, setContest] = useState();
  const [constestUpgrade, setContestUpgrade] = useState(false);
  useEffect(()=>{
    if(sessionStorage.getItem("promoRedirectURL")){
      setPromoRedirectURL(sessionStorage.getItem("promoRedirectURL"))
      sessionStorage.removeItem("promoRedirectURL")
    }

    if(sessionStorage.getItem("contestSession")){
      setContest(sessionStorage.getItem("contestSession"))
      setContestRedirectURL(sessionStorage.getItem("contestRedirectURL"))
      sessionStorage.removeItem("contestSession")
      sessionStorage.removeItem("contestRedirectURL")
      if(sessionStorage.getItem("isContestUpgrade")){
        sessionStorage.removeItem("isContestUpgrade")
        setContestUpgrade(true)
      }
    }
  },[])

  const handleSubmit = (e) => {
    //use cannot press submit again
    setDisableSubmit(true);
    e.preventDefault();
    const subscription = {
      verified: true,
      productId: account.productId,
      newProductId: account.productId,
      pricePoint: account.pricePoint,
      flow: account.flow,
      couponCodes: [],
      paymentProfile: {
        chargifyToken: "string",
        paymentType: "credit_card",
      },
    };

    chargify.current.token(
      chargifyForm.current,

      (token) => {
        addLoading("CHARGIFYACTION")
        subscription.paymentProfile.chargifyToken = token;
        if (!!authData.subscriptionId && account.productId !== undefined) {
          //if user has already subscription and wants to upgrade the subscription
          setUpgrade(true);
          AccountsService.addPaymentProfile({ chargifyToken: token })
            .then(() => {
              onUpgrade(subscription);
            })
            .catch((error) => {
              console.log("error adding payment on upgrade");
              setError(true);
              setDisableSubmit(false);
              removeLoading("CHARGIFYACTION")
            });
        } else if (account.productId === undefined) {
          //has subscription and wants to change the payment profile
          AccountsService.addPaymentProfile({ chargifyToken: token })
            .then((response) => {
              navigate("/Account");
            })
            .catch((error) => {
              console.log("error CHANGING payment");
              setError(true);
              setDisableSubmit(false);
            })
            .finally(()=>{
              removeLoading("CHARGIFYACTION")
            })
        } else {
          //user dosent have a subscription
          onSubscribe(subscription);
        }
      },

      (error) => {
        console.log("Error on chargify token");
        setError(true);
        setDisableSubmit(false);
        removeLoading("CHARGIFYACTION")
      }
    );
  };

  const onGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    if(!isLoading()){

      chargify.current.load(
        {
          publicKey: `${chargifyPKey}`,
          type: "card",
          gatewayHandle: "cybersourcetest",
          serverHost: `${chargifyHost}`,
          hideCardImage: true,
          optionalLabel: "(optional field)",
          addressDropdowns: true,
          currency: "EUR",
          threeDSecure: true,
          fields: {
            firstName: {
              selector: "#card-first-name",
              label: "FIRST NAME",
              placeholder: `${t("namePlaceholder")}`,
              required: true,
              message: `${t("chargifyError")}`,
              maxlength: "30",
            },
            lastName: {
              selector: "#card-last-name",
              label: "",
              placeholder: `${t("lastNamePlaceholder")}`,
              required: true,
              message: `${t("chargifyError")}`,
              maxlength: "30",
            },
            number: {
              selector: "#card-number",
              label: "Αριθμός κάρτας",
              required: true,
              placeholder: " ",
              message: `${t("chargifyError")}`,
            },
            month: {
              selector: "#card-exp-month",
              label: "Mon",
              required: true,
              placeholder: `${t("monthPlaceholder")}`,
              message: `${t("chargifyError")}`,
            },
            year: {
              selector: "#card-exp-year",
              label: "Year",
              placeholder: `${t("yearPlaceholder")}`,
              required: true,
              message: `${t("chargifyError")}`,
            },
            cvv: {
              selector: "#card-cvv",
              label: "CVV code",
              required: true,
              placeholder: " ",
              message: `${t("chargifyError")}`,
            },
            address: {
              selector: "#card-address",
              required: true,
            },
            city: {
              selector: "#card-city",
              required: true,
            },
            zip: {
              selector: "#card-zip",
              required: true,
            },
            country: {
              selector: "#card-country",
              required: true,
            },
          },
          style: {
            "#chargify-form": { border: "1px dashed #ffc0cb57" },
            field: {
              marginBottom: "0",
              overflow: "hidden"
            },
            input: {
              paddingTop: "2px",
              paddingBottom: "1px",
              placeholder: { color: "rgba(255,255,255, 0.6)" },
              borderColor: "rgba(255,255,255, 0.6)",
              backgroundColor: "#000000",
              color: "#FFFFFF",
              height: "46px",
              fontSize: "16px",
            },
            label: {
              display: "none",
            },
            message: {fontSize: "11px"},
          },
        },
        {
          onThreeDsConfigError: function (error) {
            console.error(error);
          },
        }
      );
      // eslint-disable-next-line
      return () => chargify.current.unload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeLoading]);//todo

  const onUseSameCard = (e) => {
    addLoading("CHARGIFYACTION");
    tempSubscription.noTrialAccepted = true;
    setCardInUse(false);
    upgrade ? onUpgrade(tempSubscription) : onSubscribe(tempSubscription);
  };

  const onUpgrade = (subscription) => {
    subscription.paymentProfile.chargifyToken = undefined;

    let request = {
      ...subscription,
      skipFilters: account.skipFilters,
      ...(sessionStorage.getItem("save_desk_token") !== null ? { token: sessionStorage.getItem("save_desk_token") } : {})
    };
    if (sessionStorage.getItem("discount-coupon")) {
      request = {
        ...request,
        couponCodes: [`${sessionStorage.getItem("discount-coupon")}`],
      };
    }
    AccountsService.upgradeSubscription(request)
      .then((res) => {
        const newProduct = res.data.subscription.product
        account.setupAnalyticsProductConvertion({
          pricePoint: newProduct.product_price_point_handle,
          productName: newProduct.name,
          pricePointId: newProduct.product_price_point_id,
        })

        // console.log("SUCCESS on upgrade");
        sessionStorage.removeItem("save_desk_token");
        if (sessionStorage.getItem("discount-coupon")) {
          setGoToAccount(true);
          setSuccess(true);
          setCouponFail(false);
          sessionStorage.removeItem("discount-coupon");
          // removeLoading("CHARGIFYACTION")
        } else {
          if(promoRedirectURL){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
          }
          if(constest){
            sessionStorage.setItem("contestSession", constest)
            sessionStorage.setItem("contestRedirectURL", constestRedirectURL)
            sessionStorage.setItem("isContestUpgrade", constestUpgrade ? "true": "");
          }
          navigate("/CongratsPage");
        }
      })
      .catch((error) => {
        // console.error("Upgrade error");
        if (error.response.data.code === 403) {
          if(promoRedirectURL){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
          }
          if(constest){
            sessionStorage.setItem("contestSession", constest)
            sessionStorage.setItem("contestRedirectURL", constestRedirectURL)
            sessionStorage.setItem("isContestUpgrade", constestUpgrade ? "true": "");
          }
          setCheckLoggedIn(false);
          sessionStorage.setItem("cardValidationFlow", "upgrade");
          sessionStorage.setItem("cardValidationToken", error.response.data.token);
          sessionStorage.setItem("subscriptionRequest", JSON.stringify(request));
          navigate("/PhoneVerification");
        } else if (error.response.data.code === 404) {
          setTempSubscription(request);
          setCardInUse(true);
        } else if (
          sessionStorage.getItem("discount-coupon") &&
          [4, 101, 421, 423, 424].includes(error.response.data.code)
        ) {
          setSuccess(false);
          setCouponFail(true);
          sessionStorage.removeItem("discount-coupon");
        }
        setDisableSubmit(false);
        setSuccess(false);
      })
      .finally(()=>{
        removeLoading("CHARGIFYACTION")
      })
  };

  const onSubscribe = (subscription) => {
    let request = subscription;
    if (sessionStorage.getItem("discount-coupon")) {
      // console.log("coupon exists charg");
      request = {
        ...subscription,
        couponCodes: [`${sessionStorage.getItem("discount-coupon")}`],
      };
    }
    AccountsService.subscribe(request)
      .then((res) => {
        const newProduct = res.data.subscription.product
        account.setupAnalyticsProductConvertion({
          pricePoint: newProduct.product_price_point_handle,
          productName: newProduct.name,
          pricePointId: newProduct.product_price_point_id,
        })

        // console.log("successful subscription");
        sessionStorage.setItem("kc_resolve_token", res.data.token);

        if (UserService.isSubscriptionAction()) {
          if (sessionStorage.getItem("discount-coupon")) {
            setGoToAccount(false);
            setSuccess(true);
          } else {
            if(promoRedirectURL){
              sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
            }
            if(constest){
              sessionStorage.setItem("contestSession", constest)
              sessionStorage.setItem("contestRedirectURL", constestRedirectURL)
              sessionStorage.setItem("isContestUpgrade", constestUpgrade ? "true": "");
            }
            navigate("/CongratsPage");
          }
        } else {
          if(promoRedirectURL){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
          }
          if(constest){
            sessionStorage.setItem("contestSession", constest)
            sessionStorage.setItem("contestRedirectURL", constestRedirectURL)
            sessionStorage.setItem("isContestUpgrade", constestUpgrade ? "true": "");
          }
          navigate("/CongratsPage");
        }
        sessionStorage.removeItem("discount-coupon");
      })
      .catch((error) => {
        // console.log("Error on subscription");
        if (error.response.data.code === 403) {
          if(promoRedirectURL){
            sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
          }
          if(constest){
            sessionStorage.setItem("contestSession", constest)
            sessionStorage.setItem("contestRedirectURL", constestRedirectURL)
            sessionStorage.setItem("isContestUpgrade", constestUpgrade ? "true": "");
          }
          setCheckLoggedIn(false);
          sessionStorage.setItem("cardValidationToken", error.response.data.token);
          sessionStorage.setItem("subscriptionRequest", JSON.stringify(request));
          navigate("/PhoneVerification");
        } else if (error.response.data.code === 404) {
          setTempSubscription(subscription);
          setCardInUse(true);
        } else if (
          sessionStorage.getItem("discount-coupon") &&
          [4, 101, 421, 423, 424].includes(error.response.data.code)
        ) {
          setSuccess(false);
          setCouponFail(true);
          sessionStorage.removeItem("discount-coupon");
        } else {
          // console.log("this is a test5")
          setError(true);
        }

        setDisableSubmit(false);
        setSuccess(false);
      })
      .finally(()=>{
        removeLoading("CHARGIFYACTION")
      })
  };

  return (
    <>
      {isLoading() ? (
        <Loader />
      ) : (
        <div className="chargify-js-wrapper box">
          <div className="chargify-js-content">
            <h1 id="chargify-title">{t("chargifyFormTitle")}</h1>
            <h3 id="chargify-sub-title">{t("chargifyFormInfo")}</h3>
            <form
              className="chargify-form"
              id="chargify-form"
              onSubmit={handleSubmit}
              ref={chargifyForm}
            >
              <div className="cfy-input">
                <label>
                  {t("chargifyFormCardNumber")} <VisaLogo /> <MasterCardLogo />
                </label>

                <div className="card-number-wrapper">
                  <div id="card-number">
                    <LockOutlinedIcon fontSize="tiny" id="card-lock-icon" />
                  </div>
                </div>
              </div>

              <div className="card-info-wrapper">
                <div className="cfy-input">
                  <label>{t("expirationDate")}</label>
                  <div className="card-date">
                    <div id="card-exp-month"></div>
                    <div id="card-exp-year"></div>
                  </div>
                </div>

                <div className="cfy-input">
                  <label>CVV</label>
                  <div className="card-cvv-wrapper">
                    <div id="card-cvv"></div>
                  </div>
                </div>
              </div>
              <div className="cfy-input">
                <label>{t("fullName")}</label>
                <div className="card-name-wrapper">
                  <div id="card-first-name"></div>
                  <div id="card-last-name"></div>
                </div>
              </div>
              <input
                id="card-address"
                data-chargify="address"
                type="hidden"
                value="."
              />
              <input
                id="card-city"
                data-chargify="city"
                type="hidden"
                value="."
              />
              <input
                id="card-zip"
                data-chargify="zip"
                type="hidden"
                value="00000"
              />
              <input
                id="card-country"
                data-chargify="country"
                type="hidden"
                value="GR"
              />
            </form>

            <div id="chargify-buttons">
              <div>
                <Button variant="text" color="white" className="grey" onClick={onGoBack}>
                  {t("goBackButton")}
                </Button>
              </div>

              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={disableSubmit}
                  id="payment-button"
                >
                  {account.changePayment
                    ? t("changePaymentButton")
                    : t("paymentButton")}
                </Button>
              </div>
            </div>
            <div id="legal-info">
              <p>
                Θα θέλαμε να σας ενημερώσουμε ότι, κάνοντας κλικ στην επιλογή
                «Πληρωμή», μας παρέχετε τη συναίνεσή σας για να ξεκινήσει άμεσα
                η συνδρομή σας για την περίοδο χρέωσης και με τον τρόπο πληρωμής
                που έχετε αποθηκεύσει.
              </p>
              <p>
                Αναγνωρίζετε επίσης ότι, με την εκτέλεση της σύμβασης και την
                παρακολούθηση του περιεχομένου του ΑΝΤ1+ (σε μορφή streaming),
                δεν θα έχετε δικαίωμα υπαναχώρησης εντός 14 ημερολογιακών ημερών
                σύμφωνα με τον νόμο και τη σύμβασή μας. Η χρέωσή σας θα
                πραγματοποιείται σε μηνιαία βάση εφόσον έχετε επιλέξει τη μηναία
                συνδρομή αυτόματης ανανέωσης. Αν έχετε επιλέξει συνδρομή για
                μεγαλύτερη περίοδο (π.χ. 6μηνη ή 9μηνη), εφόσον έχει διαμορφωθεί
                τέτοια, η χρέωση του ποσού θα πραγματοποιείται εφάπαξ κατά την
                εγγραφή σας, ή/και κατά την αυτόματη ανανέωσή της, εφόσον
                προβλέπεται τέτοια. Εκτός από αυτό, μπορείτε να ακυρώσετε ανά
                πάσα στιγμή τη συνδρομή σας, με ισχύ από την ημερομηνία λήξης
                της περιόδου χρέωσης ή της δωρεάν δοκιμής, αν υφίσταται. Κατά
                συνέπεια, δεν σας παρέχεται η δυνατότητα επιστροφής χρημάτων ή
                πίστωσης για μη ολοκληρωμένους μήνες ή περιόδους, με την
                εξαίρεση των περιπτώσεων που προβλέπονται στους Όρους Παροχής
                και Χρήσης της Υπηρεσίας.
              </p>
            </div>
          </div>
          <PopUp
            openPopUp={error}
            setOpenPopUp={setError}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setError}
              mainText={"Σφάλμα κατά την πληρωμή!"}
              secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
            />
          </PopUp>
          <PopUp
            openPopUp={success}
            setOpenPopUp={setSuccess}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
            closeWhenClickedOutside={false}
          >
            <GenericError
              setClose={setSuccess}
              success={true}
              mainText={"Επιτυχής εξαργύρωση κωδικού!"}
              buttonText={"Συνέχεια"}
              showIcon={false}
              executeFunction={() => onAfterSuccessfulCouponUse()}
            />
          </PopUp>
          <PopUp
            openPopUp={couponFail}
            setOpenPopUp={setCouponFail}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setCouponFail}
              success={true}
              mainText={"Αποτυχία εξαργύρωσης!"}
              secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
              buttonText={"Συνέχεια"}
              showIcon={false}
              executeFunction={() => navigate("/Account")}
            />
          </PopUp>

          <PopUp
            openPopUp={cardInUse}
            setOpenPopUp={setCardInUse}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setCardInUse}
              success={true}
              mainText={t("cardHashError")}
              secondaryText={t("cardHashErrorDescription")}
              hideButtons={true}
              showIcon={false}
            />
            <div id="card-in-use-actions">
              <Button
                variant="text"
                color="primary"
                className="grey"
                onClick={() => {
                  setCardInUse(false);
                }}
              >
                Αλλαγή κάρτας
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onUseSameCard}
              >
                Συμφωνώ & επιθυμώ να συνεχίσω
              </Button>
            </div>
          </PopUp>
        </div>
      )}
    </>
  );
};

export default ChargifyForm;
