import React, { useState, useEffect } from "react";
import "./PhoneVerification.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { Alert, IconButton, InputAdornment } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useNavigate } from "react-router-dom";
import PinVerification from "../PinVerification/PinVerification";
import PopUp from "../Utilities/PopUp";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import CardValidationService from "../../services/CardValidationService";
import { ReactComponent as CyprusFlag } from "./cyprus.svg";
import { ReactComponent as GreeceFlag } from "./greece.svg";
import GenericError from "../Utilities/GenericError";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import AccountsService from "../../services/AccountsService";
import UserService from "../../services/UserService";
import {useAccount} from "../../context/AccountContext";

function PhoneVerification() {
  let navigate = useNavigate();
  let account = useAccount();
  const { t } = useTranslation();

  const { setCheckLoggedIn } = useAuth()



  const [country, setCountry] = useState("+30");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [otpFailed, setOtpFailed] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const handleOpen = () => setOpen(true);

  const {isLoading, removeLoading, addLoading} = useLoading()

  const [promoRedirectURL, setPromoRedirectURL] = useState()

  const [success, setSuccess] = useState(false);
  const [couponFail, setCouponFail] = useState(false);
  const [actionError, setActionError] = useState(false);


  useEffect(()=>{
    if(sessionStorage.getItem("promoRedirectURL")){
      setPromoRedirectURL(sessionStorage.getItem("promoRedirectURL"))
      sessionStorage.removeItem("promoRedirectURL")
    }
  },[])

  useEffect(() => {//this page is not interestead in loading, if we are here we are here.
    if(isLoading("INIT")){
      removeLoading("INIT")
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleChange = (e) => {
    e.preventDefault();
    setCountry(e.target.value);
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setPhone(value);
    setError(false);
  };

  const onValidateNumber = () => {
    if (isValidPhoneNumber(country + phone) || phone.length === 0) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const onSubmit = (e, mobile) => {
    e.preventDefault();
    addLoading("PHONEVERIFYSUBMIT")
    setDisabledButton(true);
    setTimeout(() => setDisabledButton(false), 5000);
    setCheckLoggedIn(false);

    CardValidationService.generateOTP({phoneNumber: country + phone},
        sessionStorage.getItem("cardValidationToken"))
        .then((response) => {
          if(response.data.code === 301){
            sessionStorage.setItem("phoneVerificationToken", response.data.token);
            if (!mobile) {
              handleOpen();
            } else {
              navigate("/PinVerification", {state: { phoneNumber: country + phone }});
            }
          }else{
            var flow = sessionStorage.getItem("cardValidationFlow");
            let isUpgrade = !!flow && flow === "upgrade";
            if (isUpgrade) {
              return onUpgrade(response.data.token);
            }else {
              return onSubscribe(response.data.token);
            }
          }
        })
        .catch((error) => {
          setOtpFailed(true);
        })
        .finally(()=>{
          removeLoading("PHONEVERIFYSUBMIT")
        })
  };

  const goBack = (e) => {
    e.preventDefault();
    setCheckLoggedIn(false);
    account.setChargifyAccess(true);
    navigate("/ChargifyForm");
  };

  const onUpgrade = (flowToken) => {
    const request = JSON.parse(sessionStorage.getItem("subscriptionRequest"));
    request.paymentProfile = {
      flowToken: flowToken,
    };
    return AccountsService.upgradeSubscription(request)
        .then((res) => {
          const newProduct = res.data.subscription.product
          account.setupAnalyticsProductConvertion({
            pricePoint: newProduct.product_price_point_handle,
            productName: newProduct.name,
            pricePointId: newProduct.product_price_point_id,
          })

          if (sessionStorage.getItem("discount-coupon")) {
            setSuccess(true);
            setCouponFail(false);
            sessionStorage.removeItem("discount-coupon");
          } else {
            if(promoRedirectURL){
              sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
            }
            navigate("/CongratsPage");
          }

          sessionStorage.removeItem("cardValidationFlow");
          sessionStorage.removeItem("cardValidationToken");
          sessionStorage.removeItem("subscriptionRequest");
        })
        .catch((error) => {
          if (
              sessionStorage.getItem("discount-coupon") &&
              [4, 101, 421, 423, 424].includes(error.response.data.code)
          ) {
            setCouponFail(true);
            sessionStorage.removeItem("discount-coupon");
          } else {
            setSuccess(false);
            setCouponFail(false);
            setActionError(true);
          }
        });
  };

  const onSubscribe = (flowToken) => {
    if(sessionStorage.getItem("subscriptionRequest")){
      const request = JSON.parse(sessionStorage.getItem("subscriptionRequest"));
      request.paymentProfile = {
        flowToken: flowToken,
      };

      return AccountsService.subscribe(request)
          .then((res) => {
            const newProduct = res.data.subscription.product
            account.setupAnalyticsProductConvertion({
              pricePoint: newProduct.product_price_point_handle,
              productName: newProduct.name,
              pricePointId: newProduct.product_price_point_id,
            })

            sessionStorage.setItem("kc_resolve_token", res.data.token);

            if (UserService.isSubscriptionAction()) {
              if (sessionStorage.getItem("discount-coupon")) {
                setSuccess(true);
                setCouponFail(false);
              } else {
                if(promoRedirectURL){
                  sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
                }
                navigate("/CongratsPage");
              }
            } else {
              if(promoRedirectURL){
                sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
              }
              navigate("/CongratsPage");
            }
            sessionStorage.removeItem("discount-coupon");
            sessionStorage.removeItem("cardValidationToken");
            sessionStorage.removeItem("subscriptionRequest");
          })
          .catch((error) => {
            if (
                sessionStorage.getItem("discount-coupon") &&
                [4, 101, 421, 423, 424].includes(error.response.data.code)
            ) {
              setCouponFail(true);
              sessionStorage.removeItem("discount-coupon");
            } else {
              setActionError(true);
              setSuccess(false);
            }
          });
    }
  };

  const onAfterSuccessfulCouponUse = () => {
    if(promoRedirectURL){
      sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
    }
    sessionStorage.setItem("coupon-success", true);
    navigate("/CongratsPage");
  };

  const closeFailActionPopUp = () => {
    setActionError(false);
  };

  return (
    <>
      {isLoading() ? (
        <Loader />
      ) : (
        <div className="box big padded">
          <PopUp
            openPopUp={open}
            setOpenPopUp={setOpen}
            maxWidth="sm"
            showCloseButton={true}
            outlined={true}
          >
            <PinVerification
              showBackButton={false}
              isModal={true}
              mobile={country + phone}
            />
          </PopUp>

          <div className="phone-verification-wrapper">
            <p style={{ whiteSpace: "pre-line" }}>Συμπλήρωσε τον αριθμό του κινητού σου</p>

            <form onSubmit={onSubmit} autoComplete="off">
              <div className="form-fields">
                <div className="field" id="country-code-field">
                  <label>{t("countryCode")}</label>
                  <Select
                    variant="outlined"
                    value={country}
                    name="countryCode"
                    onChange={handleChange}
                    onClick={() => setOpenSelect(!openSelect)}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenSelect(!openSelect)}
                      />
                    )}
                    style={{
                      maxWidth: "fit-content",
                    }}
                    open={openSelect}
                  >
                    <MenuItem value={"+30"} style={{}}>
                      <GreeceFlag className="flag-icon" />
                      <span>+30</span>
                    </MenuItem>
                    <MenuItem value={"+357"}>
                      <CyprusFlag className="flag-icon" />
                      <span>+357</span>
                    </MenuItem>
                  </Select>
                </div>
                <div className="field" id="phone-number-field">
                  <label>{t("phoneNumber")}</label>
                  <TextField
                    error={error}
                    variant="outlined"
                    fullWidth
                    type="number"
                    name="phone"
                    onChange={handlePhoneChange}
                    onBlur={onValidateNumber}
                    className={`default ${error ? "error" : ""}`}
                    value={phone}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ color: "#C64E4E" }}>
                            {error && <PriorityHighIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error && (
                    <Alert
                      id="wrong-number-alert"
                      severity="error"
                      sx={{
                        backgroundColor: "transparent",
                        color: "#C64E4E",
                        padding: "0",
                      }}
                      icon={false}
                    >
                      {t("phoneVerAlert")}
                    </Alert>
                  )}
                </div>
              </div>
              <div className={"information-box"}>
                <img src={"images/account/unsubscribeIcon.svg"} alt={"information box"}/>
                <span>Ως επιπλέον βήμα ασφαλείας, ενδέχεται να λάβεις έναν κωδικό μίας χρήσης, ώστε να επαληθεύσεις τον αριθμό του κινητού σου.</span>
              </div>
              <div className={"ctas"}>
                <Button
                  variant="text"
                  className="grey"
                  color="white"
                  onClick={goBack}
                  id="goBackButton"
                >
                  {t("goBackButton")}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => onSubmit(e, true)}
                  className="sendPinButton mobile"
                  disabled={disabledButton}
                >
                  Συνέχεια
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => onSubmit(e, false)}
                  className="sendPinButton desktop"
                  disabled={disabledButton}
                >
                  Συνέχεια
                </Button>
              </div>
            </form>
          </div>
          <PopUp
            openPopUp={otpFailed}
            setOpenPopUp={setOtpFailed}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
          >
            <GenericError
              setClose={setOtpFailed}
              success={true}
              mainText={"Αποτυχία αποστολής κωδικού!"}
              secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
              buttonText={"Συνέχεια"}
              showIcon={false}
              executeFunction={() => setOtpFailed(false)}
            />
          </PopUp>
          <PopUp
              openPopUp={success}
              setOpenPopUp={setSuccess}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={false}
          >
            <GenericError
                setClose={setSuccess}
                success={true}
                mainText={"Επιτυχής εξαργύρωση κωδικού!"}
                buttonText={"Συνέχεια"}
                showIcon={false}
                executeFunction={() => onAfterSuccessfulCouponUse()}
            />
          </PopUp>
          <PopUp
              openPopUp={couponFail}
              setOpenPopUp={setCouponFail}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
          >
            <GenericError
                setClose={setCouponFail}
                success={true}
                mainText={"Αποτυχία εξαργύρωσης!"}
                secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
                buttonText={"Συνέχεια"}
                showIcon={false}
                executeFunction={() => navigate("/Account")}
            />
          </PopUp>
          <PopUp
              openPopUp={actionError}
              setOpenPopUp={setActionError}
              maxWidth="md"
              showCloseButton={false}
              outlined={false}
              error={true}
          >
            <GenericError
                setClose={setActionError}
                mainText={"Σφάλμα κατά την πληρωμή!"}
                secondaryText={"Παρακαλώ δοκιμάστε αργότερα"}
                executeFunction={closeFailActionPopUp}
            />
          </PopUp>
        </div>
      )}
    </>
  );
}

export default PhoneVerification;
