import axios from "axios";
import {
  baseUrl,
  postOptions,
} from "../config.js";
class QuestionnaireService {
  constructor(baseUrl) {
    this.basePath = "/questionnaire";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  submitQuestionnaire(data) {
    return axios(postOptions(this.baseUrl, `/submit`, data));
  }
}

export default new QuestionnaireService(baseUrl);  //eslint-disable-line import/no-anonymous-default-export
